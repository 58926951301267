import { motion } from "framer-motion";
import { Link } from "gatsby";
import * as React from "react";
import classes from "../utils/classes";
import Pattern from "../components/pattern";

import countries from "../../content/countries.json";
import * as analyticStyle from "./__styles/analytics.module.scss";
import Meta from "../components/meta";
import { NavContext } from "../components/nav";

const Analytics = () => {
  const navContext = React.useContext(NavContext);

  const [analytics, setAnalytics] = React.useState();

  React.useEffect(() => {
    navContext.setNav([{ name: "Analytics", to: "/analytics" }]);
  }, []);

  React.useEffect(() => {
    const getPagecount = async () => {
      const result = await fetch("/.netlify/functions/getSiteAnalytics", {
        method: "POST",
      });

      const analytics = await result.json();

      setAnalytics(analytics);
    };

    getPagecount();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      variants={{
        enter: { opacity: 1, transition: { duration: 0.4 } },
        exit: { opacity: 0, transition: { duration: 0.2 } },
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -1,
          top: 0,
          left: "10%",
          width: "80%",
          height: "10vh",
          opacity: 0.3,
        }}
      >
        <Pattern
          background="transparent"
          overflow="none"
          sizeRange={[100, 400]}
        />
      </div>
      <Meta
        title={`Quentin Golsteyn | Analytics`}
        description="An overview of the site usage over the last 30 days."
        canonical={`https://golsteyn.com/analytics`}
      />
      <section className="section">
        <h3>Site usage over the last 30 days</h3>
        <h5>Overview</h5>
        <div className={analyticStyle.analyticBlock}>
          <div className={analyticStyle.summaryBlock}>
            <div>
              <div className={analyticStyle.label}>Visitors</div>
              <div className={analyticStyle.largeValue}>
                {analytics?.overall.visitor || "-"}
              </div>
            </div>
            <div>
              <div className={analyticStyle.label}>Visits</div>
              <div className={analyticStyle.largeValue}>
                {analytics?.overall.visit || "-"}
              </div>
            </div>
            <div>
              <div className={analyticStyle.label}>Total pageviews</div>
              <div className={analyticStyle.largeValue}>
                {analytics?.overall.pageview || "-"}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <h5>Pages</h5>
        <div className={analyticStyle.analyticBlock}>
          <div className={analyticStyle.pageviewTableRow}>
            <div className={analyticStyle.label}>Page</div>
            <div className={classes("right-align", analyticStyle.label)}>
              Pageviews
            </div>
            <div className={classes("right-align", analyticStyle.label)}>
              Avg. Time (s)
            </div>
            <div className={classes("right-align", analyticStyle.label)}>
              Avg. % Read
            </div>
          </div>
          {analytics ? (
            analytics.pageviews.map((pageview) => (
              <div
                key={pageview.path}
                className={analyticStyle.pageviewTableRow}
              >
                <div>
                  <Link to={pageview.path}>{pageview.path}</Link>
                </div>
                <div className="right-align">{pageview.pageview}</div>
                <div className="right-align">
                  {Math.round(pageview.duration)}
                </div>
                <div className="right-align">
                  {Math.round(pageview.max_scroll)}%
                </div>
              </div>
            ))
          ) : (
            <div>No data</div>
          )}
        </div>
      </section>
      <section className="section">
        <h5>Countries</h5>
        <div className={analyticStyle.analyticBlock}>
          <div className={analyticStyle.countryTableRow}>
            <div className={analyticStyle.label}>Country</div>
            <div className={classes("right-align", analyticStyle.label)}>
              Visitors
            </div>
          </div>
          {analytics ? (
            analytics.countries.map((country) => (
              <div
                key={country.country_iso}
                className={analyticStyle.countryTableRow}
              >
                <div>{countries[country.country_iso] || "unknown"}</div>
                <div className="right-align">{country.visitor}</div>
              </div>
            ))
          ) : (
            <div>No data</div>
          )}
        </div>
      </section>
    </motion.div>
  );
};

export default Analytics;
